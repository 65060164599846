import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { SilentAny } from "../utils/types";
import Layout from "../components/Layout";
import { SEO } from "../components/SEO";
import { PageOuter } from "../components/PageOuter";
import Spacer from "../components/Spacer";
import { ProjectCards } from "../components/ProjectCards";
import { OurPartnersLogosSection } from "../components/OurPartnersLogos";
import { graphql } from "gatsby";
import PageIntro from "../components/PageIntro";
import { ProjectCategoryCards } from "../components/CategoryCards";

const Projects = ({
  location,
  pageContext,
  data,
}: {
  location: LocationState;
  pageContext: SilentAny;
  data: SilentAny;
}): JSX.Element => {
  useEffect(() => {
    Aos.init({
      // can pass aos global animations here
      // alternatively, pass attribute to element directly e.g. data-aos-duration="2000"
      duration: 1500,
      once: true,
      mirror: false,
    });
  }, []);

  const { projects, projectsTotalCount, projectsByCategory } = pageContext;

  const title = "Projects";
  return (
    <Layout>
      <SEO title={title} />
      <PageOuter title={title} midLayout>
        <PageIntro
          title="Projects &#38; Partners"
          intro="We have extensive experience in a wide sector of industries and are
            perfectly placed to craft a digital solution bespoke to you."
        />
        <div data-aos="fade" data-aos-delay="250">
          <ProjectCategoryCards
            pathname={location.pathname}
            projectsByCategory={projectsByCategory}
            projectsTotalCount={projectsTotalCount}
          />
          {projects.length > 0 && (
            <>
              <Spacer height={4} />
              <ProjectCards projects={projects} />
            </>
          )}
        </div>
        <Spacer height={6} />
        <OurPartnersLogosSection data={data} />
      </PageOuter>
    </Layout>
  );
};
export const query = graphql`
  {
    threeLogo: file(relativePath: { eq: "three-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          # GatsbyImageSharpFluid_noBase64 (as opposed to GatsbyImageSharpFluid) removes jarring blur effect on title image
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    smartyLogo: file(relativePath: { eq: "smarty-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    porscheLogo: file(relativePath: { eq: "porsche-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    toyotaConnectedLogo: file(
      relativePath: { eq: "toyota-connected-logo.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    zishiLogo: file(relativePath: { eq: "zishi-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    zenziumLogo: file(relativePath: { eq: "zenzium-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    myHappyMindLogo: file(relativePath: { eq: "my-happy-mind-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sisuLogo: file(relativePath: { eq: "sisu-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    carterCorsonLogo: file(relativePath: { eq: "carter-carson-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sanFranZooLogo: file(relativePath: { eq: "san-fran-zoo-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    storeRoomLogo: file(relativePath: { eq: "store-room-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
export default Projects;
